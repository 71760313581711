import {
  Injectable
} from '@angular/core';
import {
  Http,
  Headers,
  RequestOptions
} from '@angular/http';

import {
  environment
} from '../../environments/environment';
import {
  Observable
} from "rxjs/Observable";
import 'rxjs/Rx';
import {
  map
} from 'rxjs/operators';

export interface ProspectList {
  id: Number,
    title: String,
    count: Number,
    isUpdating: boolean

}
export interface FormationList {
  id: Number,
    title: String
}
export interface SessionList {
  id: Number,
    title: String
}
const API_URL: string = environment.API_URL;

@Injectable({
  providedIn: 'root'
})
export class ProspectListService {

  private headers;

  constructor(private http: Http) {
    this.init();
  }

  async init() {
    this.headers = new Headers({
      Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('user')).token
    });
  }
  /** recuperation list des prospect
   * @returns Observable
   */
  getProspectList(): Observable < ProspectList[] > {
    return this.http.get(API_URL + '/prospect/getall',
        new RequestOptions({
          headers: this.headers
        })
      )
      .pipe(map(res => {
        let modifiedResult = res.json();
        modifiedResult = modifiedResult.map(function (movie) {
          movie.isUpdating = false;
          return movie;
        });
        return modifiedResult;
      }));
  }



  /**recuperation list session by id formation
   * @param  {} idformation
   * @returns Observable
   */
  getSessionListbyIDFormation(idformation): Observable < SessionList[] > {
    console.log('teeest');
    return this.http.get(API_URL + '/api/prospect/getsessionbyformation/' + idformation,
        new RequestOptions({
          headers: this.headers
        })
      )
      .pipe(map(res => {
        let modifiedResult = res.json();
        modifiedResult = modifiedResult.map(function (movie) {
          movie.isUpdating = false;
          return movie;
        });
        return modifiedResult;
      }));
  }
  /** recuperation list tous les session
   * @returns Observable
   */
  getAllSessionList(): Observable < SessionList[] > {
    return this.http.get(API_URL + '/api/prospect/getallSession/',
        new RequestOptions({
          headers: this.headers
        })
      )

      .pipe(map(res => {
        let modifiedResult = res.json();
        modifiedResult = modifiedResult.map(function (movie) {
          movie.isUpdating = false;
          return movie;
        });
        return modifiedResult;
      }));
  }

  /** recuperation list tous les session avec compteur superieur à 0
   * @returns Observable
   */
  getAllSessionByCompteur(): Observable < SessionList[] > {
    return this.http.get(API_URL + '/api/getAllSessionByCompteur/',
        new RequestOptions({
          headers: this.headers
        })
      )

      .pipe(map(res => {
        let modifiedResult = res.json();
        modifiedResult = modifiedResult.map(function (movie) {
          movie.isUpdating = false;
          return movie;
        });
        return modifiedResult;
      }));
  }


  
  /** recuperation list tous les formations
   * @returns Observable
   */
  getFormationList(): Observable < FormationList[] > {
    return this.http.get(API_URL + '/api/formation/getall',
        new RequestOptions({
          headers: this.headers
        })
      )
      .pipe(map(res => {
        let modifiedResult = res.json();
        modifiedResult = modifiedResult.map(function (movie) {
          return movie;
        });
        return modifiedResult;
      }));
  }

}
