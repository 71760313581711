import {
  Component,
  OnInit,
  Renderer2,
  ViewChild,
  ElementRef,
  ɵConsole
} from '@angular/core';
import {
  ModalDirective
} from 'ngx-bootstrap/modal';
import {
  Formation,
  FormationService
} from '../../formation.service';
import {
  BsModalService
} from 'ngx-bootstrap/modal';
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormArray,
  FormControl
} from "@angular/forms";
import {
  environment
} from '../../environments/environment';
import {
  MustMatch
} from '../../_helpers/must-match.validator';
import {
  AccountService,
  AlertService
} from '../../_services';



@Component({
  selector: 'formation.component.html',
  templateUrl: 'formation.component.html',
  styleUrls: ['./formation.component.css']
})



export class FormationComponent {

  addForm: FormGroup;
  rows: FormArray;
  rowDip: FormArray;
  globalVar: string;
  public testVar: string = 'test';
  @ViewChild('div') div: ElementRef;
  showMe: boolean = false;
  showButton: boolean = false;
  selectedFormation: string = '';
  selectedSession: string = '';
  toAppend;
  data;
  nomFormation: string = '';
  nomSession: string = '';
  iddevis: string = '';

  // partie formations
  formations: Formation[];
  SessionFormation: Formation[];
  LengthSessionFormation: boolean = false;
  ficheRFull: boolean = false;
  Projetprofessionnel: string = '';
  adresse: string = '';
  attente: string = '';
  chiffreAffaire: string = '';
  lieu: string = '';
  nbPerso: string = '';
  raison: string = '';
  typeVente: string = '';
  commentaire: string = '';
  userType: string = '';
  checkbox: string = '';
  acceptTerms: boolean = false;
  errorMessage: string;
  isLoading: boolean = true;
  showed:boolean=true;
  loading: boolean  = false;
  loadingFinal: boolean  = false;
  clicked: boolean  = false;
  // Partie user
  UserInformations;
  nom: string;
  prenom: string;
  email: string;
  cp: string;
  pays: string;
  ville: string;
  telephone: string;
  AdressePostal: string;
  emailautre: string;
  diplome: string;
  dateNaissance: string;
  details: string;
  niveauEtude: string;
  parcours: string;
  profile: string;
  photodeprofil: string;
  testinput: string;
  status: string;
  // PARTIE STATUT
  metier: string;
  statusS: string;
  dateD: string;
  dateF: string;
  detailsS: string;
  secteurActivite: string;
  typeUser: string;
  extraDiplome: Array < number > = [];
  fruits = [];
  blockDiplome: JSON;
  blockStatus: JSON;
  blockD: any = [];
  blockS: any = [];
  indexDiplome: number;
  indexStatut: number;
  date: string;
  statusDip: string;
  closeform:string="";
  form: FormGroup;
  // loading:boolean = false;
  submitted = false;
  isShowStatut = false;
  isShowDiplome = false;
  dipTable: any = [];
  staTable: any = [];
  lengthS: number;
  lengthD: number;
  errorUploadpic:string="";
  constructor(private formationService: FormationService,
    private renderer: Renderer2,
    private modalService: BsModalService,
    private fb: FormBuilder,
    private dipl: FormBuilder,
    private formBuilder: FormBuilder,
    private accountService: AccountService,
    private alertService: AlertService
  ) {

    this.addForm = this.fb.group({
      items: [null, Validators.required],
      items_value: ["no", Validators.required]
    });
    this.rows = this.fb.array([]);


    this.addForm = this.dipl.group({
      items: [null, Validators.required],
      items_value: ["no", Validators.required]
    });
    this.rowDip = this.dipl.array([]);
  }


  ngOnInit() {
    this.getFormations();
    this.getSessionFormation();
    this.addForm.get("items_value").setValue("yes");
    this.addForm.addControl("rows", this.rows);
    this.addForm.addControl("rowDip", this.rowDip);
    this.indexStatut = 0;
    this.indexDiplome = 0;
    this.alertService.clear();
    sessionStorage.SessionName = '';
    
  }

  /**Ouvrir l'accordion des sessions by ID
   * @param  {} formationId
   */
  openAccor(formationId) {
    // console.log(formationId)
    // jQuery(".card-body"+formationId).is(':visible') ? jQuery(".card-body"+formationId).hide() : jQuery(".card-body"+formationId).show()
    if (jQuery(".card-body" + formationId).is(':visible')) {
      jQuery(".card-body" + formationId).hide()
      jQuery(".fa" + formationId).addClass('fa-plus')
      jQuery(".fa" + formationId).removeClass('fa-minus')

      jQuery('.check' + formationId).prop('checked', false);
    } else {
      jQuery(".card-body" + formationId).show()
      jQuery('.check' + formationId).prop('checked', true);
      jQuery(".fa" + formationId).removeClass('fa-plus')
      jQuery(".fa" + formationId).addClass('fa-minus')
    }
  }



  onSubmit(data) {
    console.log(data);
  }

  createItemFormGroup(): FormGroup {
    return this.fb.group({});


  }
  onAddRow() {
    var element = {
      statusS: "",
      metier: "",
      dateD: "",
      dateF: "",
      detailsS: "",
      secteurActivite: ""
    };
    element.statusS = "";
    element.dateD = "";
    element.dateF = "";
    element.metier = "";
    element.detailsS = "";
    element.secteurActivite = "";

    this.blockS.push(element);
    // this.rows.push(this.createItemFormGroup());
    this.indexStatut = this.blockS.length;
  }

  onAddDip() {
    console.log(this.blockD.length);
    var element = {
      status: "",
      date: "",
      details: ""
    };
    element.status = "";
    element.date = "";
    element.details = "";
    this.blockD.push(element);
    this.indexDiplome = this.blockD.length;
  }


   
  /**Fonction qui permet de sauvegarder un devis et générer le pdf devis
   * @param  {} id
   * @param  {} idperso
   */
  saveDevis(id, idperso) {

    let formation = this.findFormation(id);
    jQuery(".saveDevis" + id).removeClass('btn-primary');
    jQuery(".saveDevis" + id).addClass('btn-success');
    var currentdate = new Date();
    let day = currentdate.getDate() < 10 ? "0" + currentdate.getDate() : currentdate.getDate();
    var datetime = day + "-" +
      (currentdate.getMonth() + 1) + "-" +
      currentdate.getFullYear();
    jQuery(".DateDevis" + id).html(datetime);



    this.formationService
      .saveDevis(id)
      .subscribe(
        response => {
          console.log(response)
          this.getSessionFormation();
          // this.getFormations();
        },
        error => {
          this.errorMessage = < any > error;
        }
      );
    console.log(id)
    console.log(idperso)

    this.formationService
      .downloadDevis(id, idperso)
      .subscribe(
        response => {


        },
        error => {
          this.errorMessage = < any > error;
        }
      );
  }

  
  /** Récuperer toute les formations avec les sessions
   */
  getFormations() {
    this.formationService
      .getFormations()
      .subscribe(
        formations => {
          this.formations = formations;
        },
        error => this.errorMessage = < any > error
      );
  }

  
  /**  Récupérere les sessions choisies de la part du prospect
   */
  getSessionFormation() {
    this.formationService
    .getSessionFormation()
    .subscribe(
      SessionFormation => {
            this.SessionFormation = SessionFormation;
            if (SessionFormation.length >0) this.LengthSessionFormation = true

        },
        error => this.errorMessage = < any > error
      );

  }


  /** recuperation Formation by ID
   * @param  {} id
   * @returns Formation
   */
  findFormation(id): Formation {
    return this.formations.find(formation => formation.id === id);
  }



  
  /** Save partie renseignement
   * @param  {} data
   */
  saveRenseignement(data) {
    this.closeform="";
    this.loading = true;
    this.formationService
      .saveRenseignement({
        data: data,
        id: this.iddevis
      }).subscribe(
        response => {
          this.getSessionFormation();
          this.alertService.success('<h3> Merci d\'avoir rempli la fiche de renseignement </h3> <hr> Notre service secrétariat traitera votre fiche de renseignement, et vous répondra par email', {
            keepAfterRouteChange: false
          });
          this.closeform="true";
           this.loading = false;
          // setTimeout(function () {
          //   jQuery(".close").click();
          // }, 5000);

        },
        error => {
          this.getSessionFormation();
        }
      );

  }
  
  

  clickListener(){

    this.clicked = true;
  }


  /**Save partie completer le profil
   * @param  {} data
   */
  saveProfile(data){
    
    this.loadingFinal = true;
    this.testinput = sessionStorage.getItem("SessionName");

    var stringLength = this.testinput.length - 'data:image/png;base64,'.length;
    var sizeInBytes = 4 * Math.ceil((stringLength / 3))*0.5624896334383812;
    var sizeInKb=sizeInBytes/1000;

    if ( sizeInKb > 0.5 * 1024 * 1024) 
    {
      this.errorUploadpic="L'image est trop lourde, poids max 500ko ";
      this.loadingFinal = false ;
    }
    else{

  //  console.log (this.testinput);

    this.formationService
    .saveProfile(
      {
            data: data,
            staTable: this.staTable,
            dipTable: this.dipTable,
            id: this.iddevis,
            profilePic : this.testinput,
      }
      ).subscribe(
        response => {
          this.loadingFinal = false;
           jQuery(".close").click();

        },
        error => {}
      );

      }

  }


  
  /** Renseignement MODAL
   * @param  {} Renseignement
   * @param  {} nomFormation
   * @param  {} nomSession
   * @param  {} iddevis
   */
  openConfirmDialog(Renseignement, nomFormation, nomSession, iddevis) {
    this.acceptTerms = false;
    this.closeform="";
    console.log(Renseignement)
    this.alertService.clear();
    this.nomFormation = nomFormation
    this.nomSession = nomSession
    this.iddevis = iddevis
    this.formationService
      .getRenseignement(iddevis).
    subscribe(
      response => {
        let renseignement = [];
        for (let key in response) {
          if (response.hasOwnProperty(key)) {
            renseignement[key] = response[key];
          }
        }
        this.adresse = renseignement['adresse']
        this.Projetprofessionnel = renseignement['Projetprofessionnel']
        this.attente = renseignement['attente']
        this.raison = renseignement['raison']
        this.lieu = renseignement['lieu']
        this.nbPerso = renseignement['nbPerso']
        this.typeVente = renseignement['typeVente']
        this.chiffreAffaire = renseignement['chiffreAffaire']
        this.commentaire = renseignement['commentaire']
        this.userType = renseignement['userType']
        this.checkbox = renseignement['checkbox']
        console.log(this.userType)
        // userNot0
        if (this.userType == 'user0') {
          // this.modalNotUser = true;
          // this.alertService.error('<i class="fa fa-angle-double-up fa-lg mt-4"></i><h3> Merci de compléter votre profil d\'abord </h3> ', { keepAfterRouteChange: false });

        } else {

          console.log(response)
          Renseignement.show(Renseignement);
          if (this.checkbox == 'checked') {
            this.acceptTerms = true;
            console.log(this.acceptTerms)
          }
          console.log(this.acceptTerms)
        }
      },
      error => {
        // console.log('error')
      }
    );
  }

  /** Profil Modal
   * @param  {} monProfil
   */
  openProfilDialog(monProfil) {


    this.formationService
      .getUserInformations().
    subscribe(
      response => {
        let UserInformations = [];
        for (let key in response) {
          if (response.hasOwnProperty(key)) {
            UserInformations[key] = response[key];
          }
        }

        this.nom = UserInformations['nom']
        this.testinput = UserInformations['photodeprofil']
        this.prenom = UserInformations['prenom']
        this.email = UserInformations['email']
        this.pays = UserInformations['pays']
        this.ville = UserInformations['ville']
        this.telephone = UserInformations['telephone']
        this.cp = UserInformations['codePostal']
        this.AdressePostal = UserInformations['adresse_postal']
        this.emailautre = UserInformations['emailautre']
        this.diplome = UserInformations['diplome']
        this.dateNaissance = UserInformations['dateNaissance']
        // this.date = UserInformations['details']
        this.niveauEtude = UserInformations['niveauEtude']
        this.parcours = UserInformations['parcours'] || ''
        this.photodeprofil = UserInformations['photodeprofil']
        this.status = UserInformations['status']
        this.metier = UserInformations['metier']
        this.extraDiplome = UserInformations['extraDiplome']
        this.typeUser = UserInformations['typeUser']
        // console.log(response);
        if( UserInformations['blockDiplome'])
        { this.dipTable = UserInformations['blockDiplome'].data; }
        if( UserInformations['blockStatus'])
        { this.staTable = UserInformations['blockStatus'].data;  }
       
        this.lengthS = this.staTable.length+1;
        this.lengthD = this.dipTable.length+1;



        // Partie diplome
        if (UserInformations['blockDiplome']) {
          this.statusDip = (UserInformations['blockDiplome'].status) || '';
          this.date = (UserInformations['blockDiplome'].date) || '';
          this.details = (UserInformations['blockDiplome'].details) || '';
          // this.details = UserInformations['blockDiplome']['details'] || '';

          this.blockDiplome = (UserInformations['blockDiplome']['data']);
          for (let key in this.blockDiplome) {
            this.blockD[key] = this.blockDiplome[key];
            this.indexDiplome = parseInt(key) + 1;
          }
        }


        // Partie Statut
        if (UserInformations['blockStatus']) {

          this.statusS = (UserInformations['blockStatus'].statusS) || '';
          this.metier = (UserInformations['blockStatus'].metier) || '';
          this.dateD = (UserInformations['blockStatus'].dateD) || '';
          this.dateF = (UserInformations['blockStatus'].dateF) || '';
          this.detailsS = (UserInformations['blockStatus'].detailsS) || '';
          this.secteurActivite = (UserInformations['blockStatus'].secteurActivite) || '';

          this.blockStatus = (UserInformations['blockStatus']['data']);
          for (let key in this.blockStatus) {
            this.blockS[key] = this.blockStatus[key];
            this.indexStatut = parseInt(key) + 1;
          }
        }


        // getPhotodeprofil
        monProfil.show(monProfil);
      },
      error => {
        console.log(error)
      }
    );
  }

  files: any[];

  onFileChange(event) {
    this.files = event.target.files;
    // console.log(event);
  }



  onFileSelect(event) {
    sessionStorage.SessionName = '';
    this.errorUploadpic="";
    let photodeprofil64;
    if (event.target.files.length > 0) {
      
      const file = event.target.files[0];
      if ( file['size'] > 0.5 * 1024 * 1024) 
      {
        this.errorUploadpic="L'image est trop lourde, poids max 500ko ";
        this.loading = false ;
      }
      else
      {
     
      //this.form.get('profile').setValue(JSON.stringify(file));
      // console.log('this.profile');
      // console.log(this.profile);
      var filebase64 = this.profile;

      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        photodeprofil64 = reader.result;
        // console.log(photodeprofil);//base64encoded string
        // filebase64.setValue(reader.result);
        // console.log(this.result);
        sessionStorage.SessionName = this.result;

      };
      // console.log('reader.result');//base64encoded string
      // console.log(photodeprofil);//base64encoded string

      reader.onerror = function (error) {
        // console.log('Error: ', error);
      };
    }
  }
  }

  cancelPic(){
    console.log ("here");
    jQuery("input[name='profile']").val("");     
    this.errorUploadpic="";


  }
    displayStatut() {
      this.isShowStatut = true;
      this.isShowDiplome = false;
      this.statusS = 'Sélectionner votre statut';
      this.secteurActivite = 'Sélectionner votre secteur d’activité';
    }
    displayDiplome() {
      this.isShowDiplome = true;
      this.isShowStatut = false;
      this.statusDip = 'Sélectionner votre diplôme'
    }
    addDiplome(data){
      this.dipTable.push(data);
      this.lengthD = this.dipTable.length+1;
      this.statusDip = 'Sélectionner votre diplôme';
      this.date = '';
      this.details = '';
    }
    removeDiplome(index){
      this.dipTable.splice(index, 1);
      this.lengthD = this.dipTable.length+1;
    }
    
    addStatut(data){
      this.staTable.push(data);
      this.lengthS = this.staTable.length+1;
      
      this.detailsS = '';
      this.secteurActivite= '';
      this.dateD= '';
      this.dateF= '';
      this.metier= '';
      this.statusS = 'Sélectionner votre statut';
      this.secteurActivite = 'Sélectionner votre secteur d’activité';
    }
    removeStatut(index){
      this.staTable.splice(index, 1);
      this.lengthS = this.staTable.length+1;
  
    }

    
}
