import {Component, ViewChild} from '@angular/core';
import {ModalDirective} from 'ngx-bootstrap/modal';
//import { navMemberItems } from '../../_nav';
import { AccountService } from '../../_services';
import { User } from '../../_models';
import {Formation, FormationService} from '../../formation.service';
import { FormGroup, FormBuilder, Validators, FormArray } from "@angular/forms";

@Component({
  selector: 'app-dashboard',
  templateUrl: './member.component.html',
  styleUrls: ['./member.component.css']
})




export class MemberComponent {

  user: User;
   // Partie user
   UserInformations;
   nom: string;
   prenom: string;
   email: string;
   cp: string;
   pays: string;
   ville: string;
   telephone: string;
   AdressePostal: string;
   emailautre: string;
   diplome: string;
   dateNaissance: string;
   details: string;
   niveauEtude: string;
   parcours: string;
   profile: string;
   photodeprofil : string;
   testinput : string;
   status : string;
   extraDiplome: string;
   showMe: boolean = false;
  showButton: boolean = false;
  selectedFormation: string = '';
  selectedSession: string = '';
  toAppend;
  data;
  nomFormation: string = '';
  nomSession: string = '';
  iddevis: string = '';
  
  LengthSessionFormation: boolean = false;
  
  Projetprofessionnel: string = '';
  adresse: string = '';
  attente: string = '';
  chiffreAffaire: string = '';
  lieu: string = '';
  nbPerso: string = '';
  raison: string = '';
  typeVente: string = '';
  commentaire: string = '';

  errorMessage: string;
  isLoading: boolean = true;
  showed:boolean=true;
  addForm: FormGroup;
  rows: FormArray;
  globalVar:string;
  typeUser:string;
  loading : boolean;
// PARTIE STATUT
metier : string;
statusS : string;
dateD : string;
dateF : string;
detailsS : string;
secteurActivite : string;

fruits = []; 
blockDiplome : JSON;
blockStatus : JSON;
blockD :  any =  [];
blockS :  any =  [];
indexDiplome: number;
indexStatut: number;
date: string;
statusDip: string;
rowDip: FormArray;

submitted = false;
isShowStatut = false;
isShowDiplome = false;
dipTable: any = [];
staTable: any = [];
lengthS: number;
lengthD: number;
loadingFinal:boolean=false;
clicked: boolean  = false;
errorUploadpic:string="";
constructor(private accountService: AccountService, private formationService: FormationService, private fb: FormBuilder, private dipl: FormBuilder) {
    this.accountService.user.subscribe(x => this.user = x);
    



    this.addForm = this.fb.group({
      items: [null, Validators.required],
      items_value: ["no", Validators.required]
    });
    this.rows = this.fb.array([]);
   
   
    this.addForm = this.dipl.group({
      items: [null, Validators.required],
      items_value: ["no", Validators.required]
    });
    this.rowDip = this.dipl.array([]);



}

  @ViewChild('myModal') public myModal: ModalDirective;
  @ViewChild('largeModal') public largeModal: ModalDirective;
  @ViewChild('smallModal') public smallModal: ModalDirective;
  @ViewChild('primaryModal') public primaryModal: ModalDirective;
  @ViewChild('successModal') public successModal: ModalDirective;
  @ViewChild('warningModal') public warningModal: ModalDirective;
  @ViewChild('dangerModal') public dangerModal: ModalDirective;
  @ViewChild('infoModal') public infoModal: ModalDirective;

  ngOnInit(): void {
    this.addForm.get("items_value").setValue("yes");
    this.addForm.addControl("rows", this.rows);
    this.addForm.addControl("rowDip", this.rowDip);
    this.indexStatut = 0;
    this.indexDiplome = 0;
    this.getUserInfo();
    sessionStorage.SessionName = '';
    // jQuery(".navbar-toggler-icon").css("display", "none")
  }
  navigate(link, e) {
    window.open(link);
  }

  public sidebarMinimized = false;
  //public navItems = navMemberItems;
  

  logout() {
    this.accountService.logout();
}

onAddRow() {
  // console.log (this.blockS.length);

  var element = {statusS: "",metier : "", dateD :"",dateF :"",detailsS :"",secteurActivite : ""};
  element.statusS = "";
  element.dateD = "";
  element.dateF = "";
  element.metier = "";
  element.detailsS = "";
  element.secteurActivite ="";

  this.blockS.push(element);
  this.indexStatut=this.blockS.length;
}

onAddDip() {
  var element = {status: "",date : "", details :""};
  element.status = "";
  element.date = "";
  element.details = "";
  this.blockD.push(element);
  this.indexDiplome=this.blockD.length;
  
}

createItemFormGroup(): FormGroup {
  return this.fb.group({
  });
}


/** Save partie completer le profil
 * @param  {} data
 */
saveProfile(data){


  this.loadingFinal = true;
  this.testinput = sessionStorage.getItem("SessionName");
  this.formationService
  .saveProfile(
    {
          data: data,
          staTable: this.staTable,
          dipTable: this.dipTable,
          id: this.iddevis,
          profilePic : this.testinput,

    }
    ).subscribe(
      response => {
        this.loadingFinal = false;
        jQuery(".close").click();
        this.getUserInfo();
      },
      error => {
        // console.log('error')
        // this.getSessionFormation();
      }
  );



}
/** recuperation les info de l'utilisateur connecté
 */
getUserInfo(){

  this.formationService
      .getUserInformations().
      subscribe(
        response => {
        let UserInformations = [];  
        for(let key in response){  
         if(response.hasOwnProperty(key)){ 
           UserInformations[key] = response[key];
         }  
        }         

        this.nom = UserInformations['nom']
        this.testinput = UserInformations['photodeprofil']
        this.prenom = UserInformations['prenom']
        this.email = UserInformations['email']
        this.pays = UserInformations['pays']
        this.ville = UserInformations['ville']
        this.telephone = UserInformations['telephone']
        this.cp = UserInformations['codePostal']
        this.AdressePostal = UserInformations['adresse_postal']
        this.emailautre = UserInformations['emailautre']
        this.diplome = UserInformations['diplome']
        this.dateNaissance = UserInformations['dateNaissance']
        // this.date = UserInformations['details']
        this.niveauEtude = UserInformations['niveauEtude']
        this.parcours = UserInformations['parcours']
        this.photodeprofil = UserInformations['photodeprofil']
        this.status = UserInformations['status']
        this.metier = UserInformations['metier']
        this.extraDiplome = UserInformations['extraDiplome']
        // console.log(this.photodeprofil);
        
      },
      error => {
        console.log('erSSSSSSSSSror')
      }
  );
       
}

/** Information utilisateur dans le popin
 * @param  {} monProfil
 */
openProfilDialog(monProfil){

    
  this.formationService
      .getUserInformations().
      subscribe(
        response => {
        let UserInformations = [];  
        for(let key in response){  
         if(response.hasOwnProperty(key)){ 
           UserInformations[key] = response[key];
         }  
        }         

        this.nom = UserInformations['nom']
        this.testinput = UserInformations['photodeprofil']
        this.prenom = UserInformations['prenom']
        this.email = UserInformations['email']
        this.pays = UserInformations['pays']
        this.ville = UserInformations['ville']
        this.telephone = UserInformations['telephone']
        this.cp = UserInformations['codePostal']
        this.AdressePostal = UserInformations['adresse_postal']
        this.emailautre = UserInformations['emailautre']
        this.diplome = UserInformations['diplome']
        this.dateNaissance = UserInformations['dateNaissance']
        // this.date = UserInformations['details']
        this.niveauEtude = UserInformations['niveauEtude']
        this.parcours = UserInformations['parcours']
        this.photodeprofil = UserInformations['photodeprofil']
        this.status = UserInformations['status']
        this.metier = UserInformations['metier']
        this.extraDiplome = UserInformations['extraDiplome']
        this.typeUser = UserInformations['typeUser']
        // Partie diplome

        if( UserInformations['blockDiplome'])
        { this.dipTable = UserInformations['blockDiplome'].data; }
        if( UserInformations['blockStatus'])
        { this.staTable = UserInformations['blockStatus'].data;  }
        this.lengthS = this.staTable.length+1;
        this.lengthD = this.dipTable.length+1;
        


        // getPhotodeprofil
        monProfil.show(monProfil);
      },
      error => {
        console.log(error)
      }
  );
}
/**
 * selectionné photo de profil
 * @param  {} event
 */
onFileSelect(event) {
  sessionStorage.SessionName = '';
  let photodeprofil64;
    if (event.target.files.length > 0) {
        const file = event.target.files[0];
        if ( file['size'] > 0.5 * 1024 * 1024) 
        {
          this.errorUploadpic="L'image est trop lourde, poids max 500ko ";
          this.loading = false ;
        }
        else
        {

        // console.log(file);
        var filebase64=this.profile;

        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
        photodeprofil64 = reader.result;
        sessionStorage.SessionName = this.result;
        };

        reader.onerror = function (error) {
        };
      }
    }

  }

  
  clickListener(){

    this.clicked = true;
  }
  displayStatut() {
    this.isShowStatut = true;
    this.isShowDiplome = false;
    this.statusS = 'Sélectionner votre statut';
    this.secteurActivite = 'Sélectionner votre secteur d’activité';
  }
  displayDiplome() {
    this.isShowDiplome = true;
    this.isShowStatut = false;
    this.statusDip = 'Sélectionner votre diplôme';
  }
  addDiplome(data){
    this.dipTable.push(data);
    this.lengthD = this.dipTable.length+1;
    this.statusDip = 'Sélectionner votre diplôme';
    this.date = '';
    this.details = '';
  }
  removeDiplome(index){
    this.dipTable.splice(index, 1);
    this.lengthD = this.dipTable.length+1;
  }
  
  addStatut(data){
    this.staTable.push(data);
    this.lengthS = this.staTable.length+1;

    this.detailsS = '';
    this.statusS = 'Sélectionner votre statut';
    this.secteurActivite = 'Sélectionner votre secteur d’activité';
    this.dateD= '';
    this.dateF= '';
    this.metier= '';
  }
  removeStatut(index){
    this.staTable.splice(index, 1);
    this.lengthS = this.staTable.length+1;

  }
}
