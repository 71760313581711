import {
  Injectable
} from '@angular/core';
import {
  OktaAuthService
} from '@okta/okta-angular';
import {
  Http,
  Headers,
  RequestOptions
} from '@angular/http';
import {
  Observable
} from "rxjs/Observable";
import 'rxjs/Rx';
import {
  environment
} from './environments/environment';
import {
  map
} from 'rxjs/operators';

export interface Forgottenpsswrd {
  email: String
}

const API_URL: string = environment.API_URL;

@Injectable({
  providedIn: 'root'
})

export class ForgottenpsswrdService {

  constructor(private oktaAuth: OktaAuthService, private http: Http) {}
  private accessToken;
  private headers;

  async init() {
    this.accessToken = await this.oktaAuth.getAccessToken();
    this.headers = new Headers({
      Authorization: 'Bearer ' + this.accessToken
    });
  }
  /** Envoi mail de mot de passe oublié
   * @param  {} email
   * @returns Observable
   */
  sendemail(email): Observable < Forgottenpsswrd > {
    console.log(email)
    return this.http.post(API_URL + '/sendmail', email,
      new RequestOptions({
        headers: this.headers
      })
    ).pipe(map(res => res.json()));
  }


}
