import { Component, OnInit } from '@angular/core';
import { Router, RouterEvent, NavigationEnd, RoutesRecognized, ActivatedRoute } from '@angular/router';
import { filter, pairwise, first } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import 'rxjs/Rx';

import { AccountService } from '../../_services';

//import { AccountService, AlertService } from '../_services';

@Component({
  selector: 'app-login-dashboard',
  templateUrl: './login-dashboard.component.html',
  styleUrls: ['./login-dashboard.component.scss']
})
export class LoginDashboardComponent implements OnInit {
  private previousUrl: string = undefined;
  private currentUrl: string = undefined;
  private email: string = undefined;
  private adminUrl: string;

  constructor(
    private http: HttpClient, 
    private router : Router, 
    private route: ActivatedRoute, 
    private accountService: AccountService) {

    this.currentUrl = this.router.url;
    this.previousUrl = document.referrer;
    console.log('Called Constructor');
    this.route.queryParams.subscribe(params => {
      this.email = params['email'];
    });

    this.adminUrl = this.route.snapshot.queryParams['returnUrl'] || '/Secretary/Prospect-list';
  }

  public getPreviousUrl(){
    return this.previousUrl;
  }
  
  public getCurrentUrl(){
    return this.currentUrl;
  }  

  ngOnInit(): void {

    this.accountService.loginWithEmail(this.email)
      .pipe(first())
      .subscribe(
          data => {
              let user = this.accountService.userValue;
              console.log(data);
              if (user) {
                
                let x=JSON.parse(user.roles);
              
                if (x.find(n => n === "ROLE_ADMIN"))
                // authorised so return true
                this.router.navigate([this.adminUrl]);
                else // Error
                {// redirect to login
                  this.router.navigate(['login']);
                }
              }


          },
          error => {// redirect to login
            this.router.navigate(['login']);
          });
  }

}
