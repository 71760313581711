import {
  Injectable
} from '@angular/core';
import {
  OktaAuthService
} from '@okta/okta-angular';
import {
  Http,
  Headers,
  RequestOptions
} from '@angular/http';
import {
  Observable
} from "rxjs/Observable";
import 'rxjs/Rx';
import {
  environment
} from './environments/environment';
import {
  map
} from 'rxjs/operators';

export interface Dossier {
  emaill: String,
    data: String,
    id: Number,
    renseignement: String,
    userInformations: String,

}

const API_URL: string = environment.API_URL;
@Injectable({
  providedIn: 'root'
})

export class DossierService {

  private accessToken;
  private headers;

  constructor(private oktaAuth: OktaAuthService, private http: Http) {
    this.init();
  }


  async init() {
    this.headers = new Headers({
      Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('user')).token
    });
  }
  /** Recuperation devis by ID
   * @param  {} iddevis
   * @returns Observable
   */
  getDevis(iddevis): Observable < Dossier > {
    return this.http.get(API_URL + '/api/getDevis/' + iddevis,
      new RequestOptions({
        headers: this.headers
      })
    ).pipe(map(res => res.json()));
  }


  /** enregistrement dossier upload
   * @param  {} data
   * @returns Observable
   */
  saveDBFile(data): Observable < Dossier > {
    return this.http.post(API_URL + '/api/saveDBFile/', data.formData,
      new RequestOptions({
        headers: this.headers
      })
    ).pipe(map(res => res.json()));

  }

}
