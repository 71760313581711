/**
 * @param  {'app-prospect'} {selector
 * @param  {'./prospect-list.component.html'} templateUrl
 * @param  {['./prospect-list.component.css']}} styleUrls
 */

import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';

import {
  FormationList,
  SessionList,
  ProspectList,
  ProspectListService
} from './prospect-list.service';
import {
  DataTableDirective
} from 'angular-datatables';
import {
  Subject
} from 'rxjs';
import {
  Router,
  ActivatedRoute
} from '@angular/router';
import {
  environment
} from '../../environments/environment';
import {
  Title
} from "@angular/platform-browser";

@Component({
  selector: 'app-prospect',
  templateUrl: './prospect-list.component.html',
  styleUrls: ['./prospect-list.component.css']
})
export class ProspectListComponent implements AfterViewInit, OnDestroy, OnInit {

  prospects: ProspectList[];
  formations: FormationList[];
  sessions: SessionList[];
  errorMessage: string;
  isLoading: boolean = true;
  dtOptions: DataTables.Settings = {};

  @ViewChild(DataTableDirective, {
    static: false
  })
  dtElement: DataTableDirective;
  //dtTrigger: Subject = new Subject();

  /**
   * @param  {ProspectListService} privateProspectListService
   * @param  {ActivatedRoute} privateroute
   * @param  {Router} privaterouter
   * @param  {Title} privatetitleService
   */
  constructor(private ProspectListService: ProspectListService, private route: ActivatedRoute, private router: Router, private titleService: Title) {

    this.titleService.setTitle("Liste Prospect");

  }

  ngOnInit() {
    this.getFormationList();
    this.getAllSessionList();
    this.dtOptions = {
      autoWidth: false,
      ajax: {
        url: environment.API_URL + '/api/prospect/getall',
        type: "GET",
        beforeSend: function (xhr) {
          xhr.setRequestHeader('Authorization',
            'Bearer ' + JSON.parse(localStorage.getItem('user')).token);
      },
        dataSrc: function (json) {


          return json;
        }
      },
      columns: [{
          title: 'Nom',
          data: 'nom'
        }, {
          title: 'Prenom',
          data: 'prenom'
        }, {
          title: 'email',
          data: 'email'
        }, {
          title: 'Téléphone',
          data: 'telephone'
        }, {
          title: 'Formation',
          data: 'formation_nom'
        }, {
          title: 'Session',
          data: 'session_nom',
        },  {
          title: 'Envoi devis	',
          data: 'Date'
        }, {
          title: 'Statut	',
          data: 'status',
          render: function (data, type, item, meta) {
            if (type === 'display') {
              if (data == 0)
                data = "";
              else {
                data = 'Étape ' + data;
                data += `
                <div class="progress"  id="progress_devis"><div class="progress-bar" role="progressbar" style="width:` + item['round_status'] + `%" aria-valuenow="` + item['round_status'] + `" aria-valuemax="100"> </div></div>
                `;
              }
            }
            return data;
          }
        }, {
          title: 'Action à réaliser',
          data: 'action',
          render: function (data, type, item, meta) {
            if (item['status'] == 0)
              data = "";
            return data
          }
        },
        {
          title: '',
          data: 'bouton',
          render: function (data, type, item, meta) {
            if (type === 'display') {
              if (item['action'] == "" || item['status'] == 0)
                data = ' <i class="fa fa-user-o  btn btn-success btn-xs devis" data-element-id="' + item['devisID'] + '" style="margin-right: 20px;font-size: 0.8rem;" title="Suivi dossier" ></i> ';

              else
                data = '<i class="fa fa-user-o  btn btn-primary btn-xs devis" data-element-id="' + item['devisID'] + '" style="margin-right: 20px;font-size: 0.8rem;" title="Suivi dossier" ></i>';

                data+='<i class="cui-list icons btn btn-secondary btn-xs fiche" data-element-id="'+item['userID']+'" style="font-size: 0.8rem;" title="Fiche prospect globale" ></i>';

              /* data = `<div class="d-flex"> \
                    <div class="progress col-8" id="progress_devis"><div class="progress-bar" role="progressbar" style="width:`+20*(data-1)+`%" aria-valuenow="`+20*data+`" aria-valuemax="100">Etape `+data+` </div></div>
                    <button type="button" class="btn btn-primary col-2 btn-xs devis" data-element-id="`+item['userID']+`"><i class="fa fa-user-o"></i>    </button> \
                    </div>`;*/
            }



            return data;
          }
        }
      ],
      dom: 'Blfrtip',
      pagingType: 'full_numbers',
      pageLength: 20,
      lengthMenu: [5, 10, 20],
      processing: true,
      scrollX: true,
      columnDefs: [{
        width: '10%',
        targets: 8
        }
      
    
      ],
      language: {
        processing: "Traitement en cours...",
        search: ' <button class="btn btn-primary searchbtn" type="button"><i class="fa fa-search"></i> Rechercher</button>',
        searchPlaceholder: "Par nom, email, mot clé",
        lengthMenu: "Afficher _MENU_ &eacute;l&eacute;ments",
        info: "Affichage de l'&eacute;lement _START_ &agrave; _END_ sur _TOTAL_ &eacute;l&eacute;ments",
        infoEmpty: "Affichage de l'&eacute;lement 0 &agrave; 0 sur 0 &eacute;l&eacute;ments",
        infoFiltered: "(filtr&eacute; de _MAX_ &eacute;l&eacute;ments au total)",
        infoPostFix: "",
        loadingRecords: "Chargement en cours...",
        zeroRecords: "Aucun &eacute;l&eacute;ment &agrave; afficher",
        emptyTable: "Aucune donnée disponible dans le tableau",
        paginate: {
          first: "Premier",
          previous: "Pr&eacute;c&eacute;dent",
          next: "Suivant",
          last: "Dernier"
        },
        aria: {
          sortAscending: ": activer pour trier la colonne par ordre croissant",
          sortDescending: ": activer pour trier la colonne par ordre décroissant"
        }
      }
    }

    document.querySelector('body').addEventListener('click', (event) => {

      let target = < Element > event.target; // Cast EventTarget into an Element

      if ($(target).hasClass('fiche')) {
        this.redirecttoFiche(target.getAttribute('data-element-id'));
      }
      if ($(target).hasClass('devis')) {
        this.redirecttoDevis(target.getAttribute('data-element-id'));
      }
    });

  }
  /**Redirection fiche Global prospect
   * @param  {} devis
   */
  redirecttoFiche(devis) {
    this.router.navigate(['/Secretary/Prospect', devis]);
  }

  /** Redirection a page devis 
   * @param  {} devis
   */
  redirecttoDevis(devis) {
    this.router.navigate(['/Secretary/dossier', devis]);
  }
  ngAfterViewChecked(): void {}
  ngAfterViewInit(): void {}

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    // this.dtTrigger.unsubscribe();
  }

  /** Filtre tableau by Formation selectionné
   * @param  {} deviceValue
   */
  onChangeFormation(deviceValue) {
    var labelformation = deviceValue.options[deviceValue.options.selectedIndex].text;

    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // console.log(dtInstance);
      if (deviceValue.value == "all") {
        dtInstance.column(4).search('').draw();
        this.getAllSessionList();
      } else {
        dtInstance.column(4).search(labelformation).draw();
        this.getSessionListbyIDFormation(deviceValue.value);
      }

    });

  }
  /** filter tableau by Session et formation selectionné
   * @param  {} session
   * @param  {} formation
   */
  onChangeSession(session, formation) {
    var labelformation = formation.options[formation.options.selectedIndex].text;
    var labelsession = session.options[session.options.selectedIndex].text;

    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // console.log(dtInstance);
      if (formation.value == "all")
        dtInstance.column(4).search('').draw();
      else
        dtInstance.column(4).search(labelformation).draw();

      if (session.value == "all")
        dtInstance.column(5).search('').draw();
      else
        dtInstance.column(5).search(labelsession).draw();
    });

  }
  /** filter tableau by Session , formation et periode selectionné
   * @param  {} periode
   * @param  {} formation
   * @param  {} session
   */
  onChangePeriode(periode, formation, session) {
    var labelformation = formation.options[formation.options.selectedIndex].text;
    var labelperiode = periode.options[periode.options.selectedIndex].text;
    var labelsession = session.options[session.options.selectedIndex].text;

    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // console.log(dtInstance);
      if (formation.value == "all")
        dtInstance.column(4).search('').draw();
      else
        dtInstance.column(4).search(labelformation).draw();

      if (session.value == "all")
        dtInstance.column(5).search('').draw();
      else
        dtInstance.column(5).search(labelsession).draw();
      if (periode.value == "all")
        dtInstance.column(6).search('').draw();
      else
        dtInstance.column(6).search(labelperiode).draw();
    });

  }
  /** filter tableau etape selectionné
   * @param  {} etape
   */
  onChangeEtape(etape) {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // console.log(dtInstance);
      if (etape.value == "all") {
        dtInstance.column(7).search('').draw();
        this.getAllSessionList();
      } else {
        dtInstance.column(7).search(etape.value).draw();
      }

    });
  }
  /** filter tableau by action selectionné
   * @param  {} action
   */
  onChangeTodoList(action) {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // console.log(dtInstance);
      if (action.value == "all") {
        dtInstance.column(8).search('').draw();
        this.getAllSessionList();
      } else

      if (action.value == "all_action") {
        dtInstance.column(8).search('^(?!\s*$).+', true, false).draw();
      } else {
        dtInstance.column(8).search(action.value).draw();
      }

    });

  }
  /**recuperation list prospect
   */
  getProspectList() {
    this.ProspectListService
      .getProspectList()
      .subscribe(
        prospects => {
          this.prospects = prospects;
          this.isLoading = false;
          console.log(this.prospects);

        },
        error => this.errorMessage = < any > error
      );


  }
  /** recuperation list tous les session
   */
  getAllSessionList() {
    this.ProspectListService
      .getAllSessionList()
      .subscribe(
        sessions => {
          this.sessions = sessions;
          this.isLoading = false;
        },
        error => this.errorMessage = < any > error
      );
  }
  /**recuperation list session by id formation
   * @param  {} deviceValue
   */
  getSessionListbyIDFormation(deviceValue) {
    this.ProspectListService
      .getSessionListbyIDFormation(deviceValue)
      .subscribe(
        sessions => {
          this.sessions = sessions;
          this.isLoading = false;
        },
        error => this.errorMessage = < any > error
      );
  }


  /** recuperation list tous les formations
   */
  getFormationList() {
    this.ProspectListService
      .getFormationList()
      .subscribe(
        formations => {
          this.formations = formations;
          this.isLoading = false;
        },
        error => this.errorMessage = < any > error
      );

  }


}
