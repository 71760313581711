import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { filter } from 'rxjs/operators';

import { Alert, AlertType } from '../_models';

@Injectable({ providedIn: 'root' })
export class AlertService {
    private subject = new Subject<Alert>();
    private defaultId = 'default-alert';

    
    /** enable subscribing to alerts observable
     * @param  {} id=this.defaultId
     * @returns Observable
     */
    onAlert(id = this.defaultId): Observable<Alert> {
        return this.subject.asObservable().pipe(filter(x => x && x.id === id));
    }

    
    /** convenience methods
     * @param  {string} message
     * @param  {any} options?
     */
    success(message: string, options?: any) {
        this.alert(new Alert({ ...options, type: AlertType.Success, message }));
    }
    /**
     * @param  {string} message
     * @param  {any} options?
     */
    error(message: string, options?: any) {
        this.alert(new Alert({ ...options, type: AlertType.Error, message }));
    }
    /**
     * @param  {string} message
     * @param  {any} options?
     */
    info(message: string, options?: any) {
        this.alert(new Alert({ ...options, type: AlertType.Info, message }));
    }
    /**
     * @param  {string} message
     * @param  {any} options?
     */
    warn(message: string, options?: any) {
        this.alert(new Alert({ ...options, type: AlertType.Warning, message }));
    }

       
    /** main alert method 
     * @param  {Alert} alert
     */
    alert(alert: Alert) {
        console.log (alert);
        alert.id = alert.id || this.defaultId;
        this.subject.next(alert);
    }

    
    /**  clear alerts
     * @param  {} id=this.defaultId
     */
    clear(id = this.defaultId) {
        this.subject.next(new Alert({ id }));
    }
}