/**
 * @param  {'root'}} {providedIn
 */
import {
  Injectable
} from '@angular/core';
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot
} from '@angular/router';

import {
  AccountService,
  AlertService
} from '../_services';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private accountService: AccountService,
    private alertService: AlertService

  ) {}
  /** Verification l'autorisation de l'utilisateur connecté 
   * @param  {ActivatedRouteSnapshot} route
   * @param  {RouterStateSnapshot} state
   */
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    const user = this.accountService.userValue;
    const expectedRole = route.data.expectedRole;

    if (user && !this.tokenExpired(user.token)) {

      let x = JSON.parse(user.roles);

      if (x.find(n => n === expectedRole))
        // authorised so return true
        return true;
      if (expectedRole == "ALL")
        return true;
      else {
        this.router.navigate(['/login'], {
          queryParams: {
            returnUrl: state.url
          }
        });
        this.alertService.error("Vous n'avez pas le droit pour accéder à ce niveau");
        return false;
      }
    }

    // not logged in so redirect to login page with the return url
    this.router.navigate(['/login'], {
      queryParams: {
        returnUrl: state.url
      }
    });
    return false;
  }

  /** Verification si token est expiré 
   * @param  {string} token
   */
  private tokenExpired(token: string) {
    const expiry = (JSON.parse(atob(token.split('.')[1]))).exp;
    return (Math.floor((new Date).getTime() / 1000)) >= expiry;
  }

}
