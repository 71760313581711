import {Component} from '@angular/core';
import { navItems } from '../../_nav';
import { User } from '../../_models';
import { AccountService } from '../../_services';

@Component({
  selector: 'app-dashboard',
  templateUrl: './default-layout.component.html'
})
export class DefaultLayoutComponent {
  public sidebarMinimized = false;
  public navItems = navItems;

  toggleMinimize(e) {
    this.sidebarMinimized = e;
  }
  user: User;

  constructor(private accountService: AccountService) {
      this.accountService.user.subscribe(x => this.user = x);
  }
  
  logout() {
      this.accountService.logout();
  }
}
