import {
  Injectable
} from '@angular/core';
import {
  Router
} from '@angular/router';
import {
  HttpClient
} from '@angular/common/http';
import {
  BehaviorSubject,
  Observable
} from 'rxjs';
import {
  map
} from 'rxjs/operators';
import {
  AlertService
} from '../_services';

import {
  environment
} from '../environments/environment';
import {
  User
} from '../_models';
const API_URL: string = environment.API_URL;

@Injectable({
  providedIn: 'root'
})
export class AccountService {
  private userSubject: BehaviorSubject < User > ;
  public user: Observable < User > ;
  private alertService: AlertService;

  constructor(
    private router: Router,
    private http: HttpClient,

  ) {
    this.userSubject = new BehaviorSubject < User > (JSON.parse(localStorage.getItem('user')));
    this.user = this.userSubject.asObservable();
  }

  /** get User connecté
   * @returns User
   */
  public get userValue(): User {
    return this.userSubject.value;
  }
  /** fonction d'authentification
   * @param  {} username
   * @param  {} password
   */
  login(username, password) {

    return this.http.post < User > (API_URL + '/api/login', {
        username,
        password
      })
      .pipe(map(user => {
        // store user details and jwt token in local storage to keep user logged in between page refreshes

        let jwtData = user.token.split('.')[1]
        let decodedJwtJsonData = window.atob(jwtData)
        let decodedJwtData = JSON.parse(decodedJwtJsonData)
        let roles = decodedJwtData.roles
        user.roles = JSON.stringify(roles);
        localStorage.setItem('user', JSON.stringify(user));
        this.userSubject.next(user);
        return user;

      }));
  }
  /** authentification avec email seulement
   * @param  {} email
   */
  loginWithEmail(email) {

    return this.http.post < User > (API_URL + '/dashboardLogin', {
        email
      })
      .pipe(map(user => {
        // store user details and jwt token in local storage to keep user logged in between page refreshes

        let jwtData = user.token.split('.')[1]
        let decodedJwtJsonData = window.atob(jwtData)
        let decodedJwtData = JSON.parse(decodedJwtJsonData)

        let roles = decodedJwtData.roles
        user.roles = JSON.stringify(roles);
        localStorage.setItem('user', JSON.stringify(user));
        this.userSubject.next(user);
        return user;


      }));
  }
  /** déconnexion  
   */
  logout() {
    // remove user from local storage and set current user to null
    localStorage.removeItem('user');
    this.userSubject.next(null);
    this.router.navigate(['/login']);
  }

  /** Creation  compte
   * @param  {User} user
   */
  register(user: User) {
    return this.http.post(API_URL + '/register', user).pipe(map(x => {
      return x;
    }));

  }

}
