/**
 * @param  {'root'}} {providedIn
 */
import { Injectable } from '@angular/core';
import { OktaAuthService } from '@okta/okta-angular';
import { Http, Headers, RequestOptions } from '@angular/http';
import { environment } from '../../environments/environment';
import { Observable } from "rxjs/Observable";
import 'rxjs/Rx';
import { map } from 'rxjs/operators';

export interface DossierSecretary {
    emaill: String,
    data : String,
    id: Number,
    renseignement : String,
    userInformations : String,

}

const API_URL: string =environment.API_URL;

@Injectable({
    providedIn: 'root'
})

export class DossierSecretaryService {

    private accessToken;
    private headers;

    constructor(private oktaAuth: OktaAuthService, private http: Http) {
        this.init();
    }
    
    
    async init() {
        this.headers = new Headers({
            Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('user')).token
            
        });
    }

    /** Recuperation devis by ID 
     * @param  {} iddevis
     * @returns Observable
     */
    getDevis(iddevis): Observable<DossierSecretary> {
        return this.http.get(API_URL + '/api/getDevis/'+ iddevis, 
            new RequestOptions({ headers: this.headers })
        ).pipe(map(res => res.json()));
    }

    /**Enregistrement renseignement (ETAPE 1) partie secretariat 
     * @param  {} data
     * @returns Observable
     */
    saveRenseignement(data): Observable<DossierSecretary> {
        return this.http.post(API_URL + '/api/secretary/saveRenseignement',data ,
            new RequestOptions({ headers: this.headers })
        ).pipe(map(res => res.json()));
    }
    /**Enregistrement Entretien Telephonique (ETAPE 2) partie secretariat 
     * @param  {} data
     * @returns Observable
     */
    saveEntretienTelephonique(data): Observable<DossierSecretary> {
        return this.http.post(API_URL + '/api/secretary/saveEntretienTelephonique',data ,
            new RequestOptions({ headers: this.headers })
        ).pipe(map(res => res.json()));
    }
    
    /**Enregistrement Entretien Directeur (ETAPE 3) partie secretariat 
     * @param  {} data
     * @returns Observable
     */
    saveEntretienDirecteur(data): Observable<DossierSecretary> {
        return this.http.post(API_URL + '/api/secretary/saveEntretienDirecteur',data ,
            new RequestOptions({ headers: this.headers })
        ).pipe(map(res => res.json()));
    }
    
    /**Enregistrement Entretien Telephonique (ETAPE 2) partie secretariat 
     * @param  {} iddevis
     * @returns Observable
     */
    getEntretienTelephonique(iddevis): Observable<DossierSecretary> {
        return this.http.get(API_URL + '/api/secretary/getEntretienTelephonique/'+ iddevis, 
            new RequestOptions({ headers: this.headers })
        ).pipe(map(res => res.json()));
    }

    /**Recuperation  Secretary Renseignement (ETAPE 1) partie secretariat by id devis
     * @param  {} iddevis
     * @returns Observable
     */
    getSecretaryRenseignement(iddevis): Observable<DossierSecretary> {
        return this.http.get(API_URL + '/api/secretary/getSecretaryRenseignement/'+ iddevis, 
            new RequestOptions({ headers: this.headers })
        ).pipe(map(res => res.json()));
    }

    /**Recuperation  Entretien Directeur (ETAPE 2) partie secretariat by id devis
     * @param  {} iddevis
     * @returns Observable
     */
    getEntretienDirecteur(iddevis): Observable<DossierSecretary> {
        return this.http.get(API_URL + '/api/secretary/getEntretienDirecteur/'+ iddevis, 
            new RequestOptions({ headers: this.headers })
        ).pipe(map(res => res.json()));
    }

    /**Recuperation  Transmission Dossier (ETAPE 4) partie secretariat by id devis
     * @param  {} iddevis
     * @returns Observable
     */
    getTransmissionDossier(iddevis): Observable<DossierSecretary> {
        return this.http.get(API_URL + '/api/secretary/getTransmissionDossier/'+ iddevis, 
            new RequestOptions({ headers: this.headers })
        ).pipe(map(res => res.json()));
    }
    
    /**Enregistrement  Transmission Dossier (ETAPE 4) partie secretariat 
     * @param  {} data
     * @returns Observable
     */
    saveTransmissionDossier(data): Observable<DossierSecretary> {
        return this.http.post(API_URL + '/api/secretary/saveTransmissionDossier',data.formData ,
            new RequestOptions({ headers: new Headers({
                Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('user')).token,
                //'Content-Type': 'multipart/form-data',
                
                
            })
         })
        ).pipe(map(res => res.json()));
    }

    /**Enregistrement  Encaissement des arrhes (ETAPE 5) partie secretariat 
     * @param  {} data
     * @returns Observable
     */
    saveEncaissementarrhes(data): Observable<DossierSecretary> {
        return this.http.post(API_URL + '/api/secretary/saveEncaissementarrhes',data.formData ,
            new RequestOptions({ headers: new Headers({
                Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('user')).token,
                })
         })
        ).pipe(map(res => res.json()));
    }

    /**Recuperation  Encaissement des arrhes (ETAPE 5) partie secretariat by id devis
     * @param  {} iddevis
     * @returns Observable
     */
    getEncaissementarrhes(iddevis): Observable<DossierSecretary> {
        return this.http.get(API_URL + '/api/secretary/getEncaissementarrhes/'+ iddevis, 
            new RequestOptions({ headers: this.headers })
        ).pipe(map(res => res.json()));
    }

    /**Enregistrement  confirmation Inscription (ETAPE 6) partie secretariat 
     * @param  {} data
     * @returns Observable
     */
    saveconfirmationInscription(data): Observable<DossierSecretary> {
        return this.http.post(API_URL + '/api/secretary/saveConfirmationInscription',data.formData ,
        new RequestOptions({ headers: new Headers({
            Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('user')).token,
            //'Content-Type': 'multipart/form-data',
        })
     })
    ).pipe(map(res => res.json()));
    }

    /**Recuperation  Confirmation Inscription (ETAPE 6) partie secretariat by id devis
     * @param  {} iddevis
     * @returns Observable
     */
    getConfirmationInscription(iddevis): Observable<DossierSecretary> {
        return this.http.get(API_URL + '/api/secretary/getConfirmationInscription/'+ iddevis, 
            new RequestOptions({ headers: this.headers })
        ).pipe(map(res => res.json()));
    }

    /**Enregistrement  parcour pro partie secretariat 
     * @param  {} data
     * @returns Observable
     */
    save_parcour(data): Observable<DossierSecretary> {
        return this.http.post(API_URL + '/api/secretary/save_parcour',data,
            new RequestOptions({ headers: new Headers({
                Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('user')).token,
            })
         })
        ).pipe(map(res => res.json()));
    }

    SavechangeProspectSession(data): Observable<DossierSecretary> {
        return this.http.post(API_URL + '/api/secretary/SavechangeProspectSession',data ,
            new RequestOptions({ headers: this.headers })
        ).pipe(map(res => res.json()));
    }


}

