import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Import Containers
import { DefaultLayoutComponent } from './containers';

import { P404Component } from './views/error/404.component';
import { P500Component } from './views/error/500.component';
import { LoginComponent } from './account/login.component';
import { LoginDashboardComponent } from './account/login-dashboard/login-dashboard.component';
import { RegisterComponent } from './account/register.component';
import { MentionComponent } from './containers/mention.component';
import { FormComponent } from './containers/form.component';

import { OKTA_CONFIG, OktaAuthModule, OktaCallbackComponent } from '@okta/okta-angular';
import { AuthGuard } from './_helpers';
import { ForgottenpsswrdComponent } from './views/forgottenpsswrd/forgottenpsswrd.component';
import { MemberComponent } from './containers/member/member.component';
import { FormationComponent } from './views/formation/formation.component';
import { DossierComponent } from './views/dossier/dossier.component';
import { DossierSecretaryComponent } from './views/dossier-secretary/dossier-secretary.component';

import { SecretaryComponent } from './containers/secretary/secretary.component';
import { ProspectComponent } from './views/prospect/prospect.component';
import { ProspectListComponent } from './views/prospect-list/prospect-list.component';


const accountModule = () => import('./account/account.module').then(x => x.AccountModule);
const usersModule = () => import('./users/users.module').then(x => x.UsersModule);

export const routes: Routes = [
  { path: 'users', loadChildren: usersModule, canActivate: [AuthGuard] },
  { path: 'account', loadChildren: accountModule },
  { path: 'login', component: LoginComponent },
  { path: 'login-dashboard', component: LoginDashboardComponent },
  { path: 'register', component: RegisterComponent },
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full',
  },
  {
    path: 'Formation',
    component: FormationComponent,
    data: {
      title: 'Formation membre'
    }
  },
  { path: 'implicit/callback', component: OktaCallbackComponent },
  
  {
    path: '404',
    component: P404Component,
    data: {
      title: 'Page 404'
    }
  },
  {
    path: '500',
    component: P500Component,
    data: {
      title: 'Page 500'
    }
  },
  {
    path: 'mention',
    component: MentionComponent,
    data: {
      title: 'mention'
    }
  },
  {
      path: 'form',
      component: FormComponent,
      data: {
        title: 'form'
      }
  }
  /*,
  {
    path: 'login',
    component: LoginComponent,
    data: {
      title: 'Login Page'
    }
  },
  {
    path: 'register',
    component: RegisterComponent,
    data: {
      title: 'Register Page'
    }
  }*/,
  {
    path: 'Forgottenpsswrd',
    component: ForgottenpsswrdComponent,
    data: {
      title: 'Login Page'
    }
  },
  
  {
    path: '',
    component: DefaultLayoutComponent,
    data: {
      title: 'Home'
    },
    children: [
      {
        path: 'dashboard',
        loadChildren: () => import('./views/dashboard/dashboard.module').then(m => m.DashboardModule),
        canActivate: [AuthGuard],
        data: { 
          expectedRole: 'ALL'
        }
      }
    ]
  },
  

  {
    path: 'member',
    component: MemberComponent,
    canActivate: [AuthGuard],
    data: { 
      expectedRole: 'ROLE_USER',
      title: 'Member'

    },
    children: [
      
      {
        path: 'Formation',
        component: FormationComponent,
        data: {
          title: 'Formation membre'
        }
      },
      {
        path: 'dossier/:iddevis',
        component: DossierComponent,
        data: {
          title: 'Suivi dossier'
        }
      }
      

    ]
  }
  ,

  {
    path: 'Secretary',
    component: SecretaryComponent,
    canActivate: [AuthGuard],
    data: { 
      expectedRole: 'ROLE_ADMIN',
      title: 'Secretary'

    },
    children: [
      {
        path: 'Formation',
        component: FormationComponent,
        data: {
          title: 'Formation membre'
        }
      },
      {
        path: 'Prospect/:id',
        component: ProspectComponent,
        data: {
          title: 'Prospect membre'
        }
        
      },
      {
        path: 'Prospect-list',
        component: ProspectListComponent,
        data: {
          title: 'Liste Prospect '
        }
      },
      {
        path: 'dossier/:iddevis',
        component: DossierSecretaryComponent,
        data: {
          title: 'Suivi dossier'
        }
      },
      {
        path: 'Formation',
        loadChildren: () => import('./views/formation/formation.module').then(m => m.FormationModule)
      },
      

    ]
  },
  { path: '**', component: P404Component }
];

@NgModule({
  imports: [ RouterModule.forRoot(routes, { useHash: true, relativeLinkResolution: 'legacy' ,anchorScrolling:'enabled',scrollOffset: [0, 64]}) ],
  exports: [ RouterModule ]
})
export class AppRoutingModule {}
