import { Component } from '@angular/core';
import { Router } from '@angular/router';

import { AccountService } from '../_services';
import {
    
    OnInit
    
  } from '@angular/core';

@Component({ templateUrl: 'form.component.html' })
export class FormComponent implements OnInit {

    ngOnInit() {
        this.loadScript('https://smart-form.espace-demo.com/dev/public/js/workflow-form.js?inner=#form&idforme=548');
      }

      public loadScript(url: string) {
        const body = <HTMLDivElement> document.body;
        const script = document.createElement('script');
        script.innerHTML = '';
        script.src = url;
        script.async = false;
        script.defer = true;
        body.appendChild(script);
      }


    constructor() 
    
    {
    }
}