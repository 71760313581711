import { Component, OnInit } from '@angular/core';
import {Dossier, DossierService} from '../../dossier.service';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MustMatch } from '../../_helpers/must-match.validator';
import {Formation, FormationService} from '../../formation.service';
import { Http, Headers, RequestOptions } from '@angular/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-dossier',
  templateUrl: './dossier.component.html',
  styleUrls: ['./dossier.component.css']
})
export class DossierComponent implements OnInit {


  nomFormation: string;
  nomSession: string;
  status: string;
  round_status: string;
  projet_pro: string;
  attente: string;
  raison: string;
  commentaire: string;
  adresse: string;
  niveauEtude: string;
  form: FormGroup;
  devisId: string;
  dossierCondida: string ='';
  URLdossierCondida: string;
  montant: string;
  selectedFile: File;
  validationRenseignement: string;
  validationEntretienTel: string;
  validationDirectotTel: string;
  TransmissionDossier: string;
  EncaissementArrhes: string;
  dateLimitePaiement: string;
  ConfirmationInscription: string;
  loadingEtape4 = false;
  errorUpload:any="";
  numerosecretariat: string;
  date_renseignementcomplete: string;
  heure_renseignementcomplete: string;

  date_entretien_telephonique_complete: string;
  heure_entretien_telephonique_complete: string;

constructor( private http: Http, private dossierService: DossierService, private router: Router,private formBuilder: FormBuilder,    ) { }

  ngOnInit(): void {
    this.getDevis();
    let route = this.router.url
    this.devisId = route.replace( /^\D+/g, ''); // replace all leading non-digits with nothing
    this.form = this.formBuilder.group({
     
     profile: [''],

  });

    
  }

  ngAfterViewInit(): void {
    var ancre = this.router.url.split("#");
    console.log (ancre['1']);
    if (ancre['1']!=undefined)
    {
      $('html, body').animate({
        scrollTop: $("#"+ancre['1']).offset().top
    }, 2000);
    }
    else{
      var ancre = this.router.url.split("%23");
      if (ancre['1']!=undefined)
      {
        $('html, body').animate({
          scrollTop: $("#"+ancre['1']).offset().top
      }, 2000);
      }
    }
 

    // ...
  }
  

  isCollapsedAccor1: boolean = true;
  isCollapsedAccor2: boolean = true;
  isCollapsedAccor3: boolean = true;
  isCollapsedAccor4: boolean = true;
  isCollapsedAccor5: boolean = true;
  isCollapsedAccor6: boolean = true;



  /** enregistrement dossier upload
   */
  saveDBFile(){
    this.loadingEtape4 = true;
    this.errorUpload="";

      const file = (this.selectedFile)[0];
      if (file['size'] > 8 * 1024 * 1024) 
        {
          this.errorUpload="Le dossier dépasse 9 Mo, veuillez insérer un autre dossier qui ne dépasse pas les 9Mo";
          this.loadingEtape4 = false;

        }
      else
      {
      sessionStorage.file = file
      sessionStorage.name = file.name

      const formData = new FormData();
      formData.append('file', file, file.name);
      formData.append('iddevis', this.devisId);
      formData.append('fileNom', (this.selectedFile)[0]['name']);   
    
        this.dossierService
    .saveDBFile(
      {
        formData
      }
      ).subscribe(
        response => {
          this.getDevis();
          this.loadingEtape4 = false;
          console.log (response);
          console.log (typeof(response));
          let result=JSON.stringify(response);
          console.log (result);
          console.log (typeof(result));

          if (result== "error")
          {
            console.log ('erreeeeur');
            this.errorUpload="Le dossier dépasse 9 Mo, veuillez insérer un autre dossier qui ne dépasse pas les 9Mo";
          }
        },
        error => {
        }
    );
      }
  }



  //  FORMATION PART 
  /** recuperation devis info
   */
  getDevis() {
    let route = this.router.url
    var iddevis = route.replace( /^\D+/g, ''); // replace all leading non-digits with nothing

  this.dossierService
      .getDevis(iddevis)
      .subscribe(
          response => {
              this.nomFormation = response['nomFormation'];
              this.nomSession = response['nomSession'];
              this.status = response['status'];
              this.round_status = response['round_status'];
              this.projet_pro = response['projet_pro'];
              this.attente = response['attente'];
              this.raison = response['raison'];
              this.commentaire = response['commentaire'];
              this.adresse = response['adresse'];
              this.niveauEtude = response['niveauEtude'];
              this.dossierCondida = response['dossierCondida'];
              this.URLdossierCondida = response['URLdossierCondida'];
              
              this.montant = response['montant'];
              this.validationRenseignement = response['validationRenseignement'];
              this.validationEntretienTel = response['validationEntretienTel'];
              this.validationDirectotTel = response['validationDirectotTel'];
              this.TransmissionDossier = response['TransmissionDossier'];
              this.EncaissementArrhes = response['EncaissementArrhes'];
              this.dateLimitePaiement = response['dateLimitePaiement'];
              this.ConfirmationInscription = response['ConfirmationInscription'];

              this.numerosecretariat = response['numerosecretariat'] || '';
              this.date_renseignementcomplete = response['date_renseignementcomplete'] || '';
              this.heure_renseignementcomplete = response['heure_renseignementcomplete'] || '';

              this.date_entretien_telephonique_complete = response['date_entretien_telephonique_complete'] || '';
              this.heure_entretien_telephonique_complete = response['heure_entretien_telephonique_complete'] || '';
            //  console.log(response);
            },
          // error => this.errorMessage = <any>error
      );
    }



  fileChange(event): void {
    const fileList: FileList = event.target.files;
    this.selectedFile = event.target.files
    $("#labelfile").html(this.selectedFile[0].name);

    // sessionStorage.fileList = fileList

    
}




}
