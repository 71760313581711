import { Component, OnInit, ViewChild } from '@angular/core';
import {ModalDirective} from 'ngx-bootstrap/modal';
import { AccountService } from '../../_services';
import { User } from '../../_models';
import {Formation, FormationService} from '../../formation.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './secretary.component.html',
  styleUrls: ['./secretary.component.css']
})
export class SecretaryComponent implements OnInit {
  user: User;
  constructor(private accountService: AccountService, private formationService: FormationService) {
    this.accountService.user.subscribe(x => this.user = x);
}
  @ViewChild('myModal') public myModal: ModalDirective;
  @ViewChild('largeModal') public largeModal: ModalDirective;
  @ViewChild('smallModal') public smallModal: ModalDirective;
  @ViewChild('primaryModal') public primaryModal: ModalDirective;
  @ViewChild('successModal') public successModal: ModalDirective;
  @ViewChild('warningModal') public warningModal: ModalDirective;
  @ViewChild('dangerModal') public dangerModal: ModalDirective;
  @ViewChild('infoModal') public infoModal: ModalDirective;
  prenom: string;
  
  /** template secretariat
   * @returns void
   */
  ngOnInit(): void {

    this.formationService
      .getUserInformations().
      subscribe(
        response => {
        let UserInformations = [];  
        for(let key in response){  
         if(response.hasOwnProperty(key)){ 
           UserInformations[key] = response[key];
         }  
        }         

        this.prenom = UserInformations['prenom']
      },
      error => {
        console.log (error)
      }
      );
    
    }

    navigate(link, e) {
      window.open(link);
    }



  public sidebarMinimized = false;

  logout() {
    this.accountService.logout();
}


  


}

