/**
 * @param  {'app-dashboard'} {selector
 * @param  {'./register.component.html'} templateUrl
 * @param  {['./register.component.css']}} styleUrls
 */
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { MustMatch } from '../_helpers/must-match.validator';

import { AccountService, AlertService } from '../_services';

@Component({   selector: 'app-dashboard',
                templateUrl: './register.component.html',
                styleUrls: ['./register.component.css']
             })


                // @Component({
                //     selector: 'app-dashboard',
                //     templateUrl: './member.component.html',
                //     styleUrls: ['./member.component.css']
                //   })
export class RegisterComponent implements OnInit {
    form: FormGroup;
    loading = false;
    submitted = false;
    closeForm=false;

    constructor(
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private accountService: AccountService,
        private alertService: AlertService
    ) { }

    ngOnInit() {
        this.form = this.formBuilder.group({
            nom: ['', Validators.required],
            prenom: ['', Validators.required],
           // telephone: ['', Validators.required],
            email: ['', [Validators.required, Validators.email,Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
            emailautre: [''],
           // adresse: ['', Validators.required],
            //ville: ['', Validators.required],
           // codepostal: ['', Validators.required],
          //  pays: ['', Validators.required],
            password: ['', [Validators.required, Validators.minLength(6)]],
            newsletter: [''],
            confirmPassword: ['', Validators.required],

        }, {
            validator: MustMatch('password', 'confirmPassword')
        });
    }
    navigate(link, e) {
        window.open(link);
      }

    // convenience getter for easy access to form fields
    get f() { return this.form.controls; }

    checkPasswords(group: FormGroup) { // here we have the 'passwords' group
    let pass = group.get('password').value;
    let confirmPass = group.get('confirmPassword').value;
    console.log (pass === confirmPass ? null : { notSame: true } );
    return pass === confirmPass ? null : { notSame: true }     
  }

    onSubmit() {
        console.log ("submit");
        this.submitted = true;


        // reset alerts on submit
        this.alertService.clear();
        // stop here if form is invalid
        if (this.form.invalid) {
            return;
        }

        this.loading = true;
        this.form.value.email = this.form.value.email.toLowerCase();
        this.form.value.emailautre = this.form.value.emailautre.toLowerCase();

        this.accountService.register(this.form.value)
            .pipe(first())
            .subscribe(
                data => {
                        
                   // if (data=="OK")
                        if (data['errors']!=null){
                            this.alertService.error(data['errors'], { keepAfterRouteChange: true, autoClose:true });

                        }
                        else
                        {
                            this.alertService.success(data['message'], { keepAfterRouteChange: true });
                            this.closeForm=true;
                        }
                        this.loading = false;
                    //this.router.navigate(['../login'], { relativeTo: this.route });
                },
                error => {
                    

                    if (error=="OK")
                        {
                            this.closeForm=true;
                            this.alertService.success('Registration successful', { keepAfterRouteChange: true });

                        }
                    else
                     this.alertService.error(error);
                    this.loading = false;
                });
    }

    onFileSelect(event) {
       
        if (event.target.files.length > 0) {
            const file = event.target.files[0];
            //this.form.get('profile').setValue(JSON.stringify(file));
            var filebase64=this.form.get('profile');

            var reader = new FileReader();
            reader.readAsDataURL(file);

            reader.onload = function () {
           // console.log(reader.result);//base64encoded string
            filebase64.setValue(reader.result);

            };
            reader.onerror = function (error) {
            console.log('Error: ', error);
            };
        }
      }

}