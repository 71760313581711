import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { DataTablesModule } from 'angular-datatables';
import { HttpClientModule } from '@angular/common/http';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

import { AppComponent } from './app.component';

// Import containers
import { DefaultLayoutComponent } from './containers';

import { P404Component } from './views/error/404.component';
import { P500Component } from './views/error/500.component';
import { LoginComponent } from './account/login.component';
import { LoginDashboardComponent } from './account/login-dashboard/login-dashboard.component';
import { MentionComponent } from './containers/mention.component';

import { RegisterComponent } from './account/register.component';
import { ReactiveFormsModule } from '@angular/forms';
import { AlertComponent } from './_components';
import {  ErrorInterceptor } from './_helpers';
//import { fakeBackendProvider } from './_helpers';




import { OKTA_CONFIG, OktaAuthModule, OktaCallbackComponent } from '@okta/okta-angular';
import { HttpModule, } from '@angular/http';
import { FormsModule }   from '@angular/forms';
import { HttpRequest, HttpResponse, HttpHandler, HttpEvent, HttpInterceptor, HTTP_INTERCEPTORS } from '@angular/common/http';


const APP_CONTAINERS = [
  DefaultLayoutComponent
];

import {
  AppAsideModule,
  AppBreadcrumbModule,
  AppHeaderModule,
  AppFooterModule,
  AppSidebarModule,
} from '@coreui/angular';

// Import routing module
import { AppRoutingModule } from './app.routing';

// Import 3rd party components
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { ChartsModule } from 'ng2-charts';
import { ForgottenpsswrdComponent } from './views/forgottenpsswrd/forgottenpsswrd.component';
import { MemberComponent } from './containers/member/member.component';
import { FormationComponent } from './views/formation/formation.component';

//import { AlertModule } from 'ngx-bootstrap/alert';
import { ModalModule } from 'ngx-bootstrap/modal';
import { SecretaryComponent } from './containers/secretary/secretary.component';
import { ProspectComponent } from './views/prospect/prospect.component';
import { ProspectListComponent } from './views/prospect-list/prospect-list.component';
import { CollapseModule } from 'ngx-bootstrap/collapse';
// Progress Component
import { DossierComponent } from './views/dossier/dossier.component';
import { DossierSecretaryComponent } from './views/dossier-secretary/dossier-secretary.component';

import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
//import { ProgressComponent } from '../app/views/base/progress.component';

const oktaConfig = {
  issuer: 'https://localhost:4200/',
  redirectUri: 'http://localhost:4200/implicit/callback',
  clientId: '{yourClientId}'
};
@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    AppAsideModule,
    AppBreadcrumbModule.forRoot(),
    AppFooterModule,
    AppHeaderModule,
    AppSidebarModule,
    PerfectScrollbarModule,
    BsDropdownModule.forRoot(),
    TabsModule.forRoot(),
    ChartsModule,
    HttpModule,
    OktaAuthModule,
    DataTablesModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
//	AlertModule.forRoot(),
    ModalModule.forRoot(),
    CollapseModule,
    BsDropdownModule,
    ProgressbarModule.forRoot(),
	

  ],
  declarations: [
    AppComponent,
    ...APP_CONTAINERS,
    P404Component,
    P500Component,
    LoginComponent,
    LoginDashboardComponent,
    RegisterComponent,
    AlertComponent,
    ForgottenpsswrdComponent,
    MemberComponent,
    FormationComponent,
	  SecretaryComponent,
    ProspectComponent,
    DossierComponent,
    ProspectListComponent,
    DossierSecretaryComponent,
    MentionComponent
  ],
  providers: [{ provide: OKTA_CONFIG, useValue: oktaConfig },
    
    //{ provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    //{provide: HTTP_INTERCEPTORS,    useClass: FakeBackendInterceptor,    multi: true },
    //fakeBackendProvider
  ],


  

    

  bootstrap: [ AppComponent ]
})
export class AppModule { }
