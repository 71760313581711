/**
 * @param  {'app-dashboard'} {selector
 * @param  {'login.component.html'} templateUrl
 * @param  {['./register.component.css']}} styleUrls
 */
import {
  Component,
  OnInit
} from '@angular/core';
import {
  Router,
  ActivatedRoute
} from '@angular/router';
import {
  FormBuilder,
  FormGroup,
  Validators
} from '@angular/forms';
import {
  first
} from 'rxjs/operators';

import {
  AccountService,
  AlertService
} from '../_services';

@Component({
  selector: 'app-dashboard',
  templateUrl: 'login.component.html',
  styleUrls: ['./register.component.css']
})
export class LoginComponent implements OnInit {
  form: FormGroup;
  loading = false;
  submitted = false;
  adminUrl: string;
  membreUrl: string;


  /**
   * @param  {FormBuilder} privateformBuilder
   * @param  {ActivatedRoute} privateroute
   * @param  {Router} privaterouter
   * @param  {AccountService} privateaccountService
   * @param  {AlertService} privatealertService
   */
  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private accountService: AccountService,
    private alertService: AlertService
  ) {}

  ngOnInit() {
    const user = this.accountService.userValue;
    console.log(user);
    this.form = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });

    // get return url from route parameters or default to '/'
    this.adminUrl = this.route.snapshot.queryParams['returnUrl'] || '/Secretary/Prospect-list';
    this.membreUrl = this.route.snapshot.queryParams['returnUrl'] || '/member/Formation';
  }


  navigate(link, e) {
    window.open(link);
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.form.controls;
  }
  /** fonction d'authetification 
   */
  onSubmit() {
    this.submitted = true;

    // reset alerts on submit
    this.alertService.clear();

    // stop here if form is invalid
    if (this.form.invalid) {
      return;
    }

    this.loading = true;
    this.accountService.login((this.f.username.value).toLowerCase(), this.f.password.value)
      .pipe(first())
      .subscribe(
        data => {
          let user = this.accountService.userValue;
          if (user) {

            let x = JSON.parse(user.roles);

            if (x.find(n => n === "ROLE_ADMIN"))
              // authorised so return true
              this.router.navigate([this.adminUrl]);
            else {
              this.router.navigate([this.membreUrl]);

            }
          }

          this.loading = false;

        },
        error => {
          console.log(error)
          let msgError;
          if (error == "Invalid credentials.") {
            msgError = "Identifiants invalides."
          }
          else if(error == "An authentication exception occurred.")
               msgError = "Votre demande de création de compte n'est pas encore valide, veuillez consulter votre boîte e-mail afin de confirmer votre inscription."

          else 
            msgError=error;
          this.alertService.error(msgError);
          this.loading = false;
        });
  }
}
