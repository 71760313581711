import { Injectable } from '@angular/core';
import { Http, Headers, RequestOptions } from '@angular/http';
import { Observable } from "rxjs/Observable";
import 'rxjs/Rx';
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';
export interface Prospect {
    id: Number,
    title: String,
    count: Number,
    isUpdating: boolean

}
export interface Formation {
    emaill: String,
    data : String,
    id: Number,
    renseignement : String,
    userInformations : String,

}

const API_URL: string =environment.API_URL;

@Injectable({
  providedIn: 'root'
})
export class ProspectService {

    private headers;

    constructor(private http: Http) {
        this.init();
    }

    async init() {
        this.headers = new Headers({
            Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('user')).token
        });
    }
    /** recuperation prospect by ID
     * @param  {} idUser
     * @returns Observable
     */
    getProspectByID(idUser): Observable<Prospect[]> {
        return this.http.get(API_URL + '/api/prospect/getUserByID/'+idUser,
            new RequestOptions({ headers: this.headers })
        )
        .pipe(map(res => res.json()));
    }
    
    /**recuperation Devis By IDuser
     * @param  {} idUser
     * @returns Observable
     */
    getSessionbyUser(idUser): Observable<Formation[]> {
        return this.http.get(API_URL + '/api/prospect/getDevisByIDuser/'+idUser,
            new RequestOptions({ headers: this.headers })
        )
        .pipe(map(res => {
            let modifiedResult = res.json();
            modifiedResult = modifiedResult.map(function(SessionFormation) {
                console.log(SessionFormation)
                return SessionFormation;
            });
            return modifiedResult;
        }));
    }

    /**recuperation photo de profil By IDuser
     * @param  {} idUser
     * @returns Observable
     */
    getPhotoUserByID(idUser): Observable<Formation[]> {
        return this.http.get(API_URL + '/api/prospect/getPhotoUserByID/'+idUser,
        new RequestOptions({ headers: this.headers })
        )
        .pipe(map(res => res.json()));
    }

    getCommentaireDashboard(id_personne): Observable<Formation[]> {
        return this.http.get(API_URL + '/api/prospect/getCommentaireDashboard/'+id_personne,
        new RequestOptions({ headers: this.headers })
        )
        .pipe(map(res => res.json()));
    }

    saveCommentaireDashboard(data): Observable < Formation > {
        return this.http.post(API_URL + '/api/prospect/saveCommentaireDashboard/', data,
          new RequestOptions({
            headers: this.headers
          })
        ).pipe(map(res => res.json()));
    
      }

}