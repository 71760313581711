import {
  Component,
  OnInit
} from '@angular/core';
import {
  ActivatedRoute
} from '@angular/router';
import {
  NgForm
} from '@angular/forms';
import {
  Formation,
  ProspectService
} from './prospect.service';
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormArray,
  FormControl
} from "@angular/forms";
import {
  DossierSecretaryService
} from '../dossier-secretary/dossier-secretary.service';

import { FormationService} from '../../formation.service';

@Component({
  selector: 'app-prospect',
  templateUrl: './prospect.component.html',
  styleUrls: ['./prospect.component.css']
})
export class ProspectComponent implements OnInit {
  id: number;
  private sub: any;

  // Partie user
  UserInformations;
  nom: string;
  prenom: string;
  email: string;
  cp: string;
  pays: string;
  ville: string;
  telephone: string;
  AdressePostal: string;
  emailautre: string;
  diplome: string;
  dateNaissance: string;
  details: string;
  niveauEtude: string;
  parcours: string;
  profile: string;
  photodeprofil: string;
  testinput: string;
  status: string;
  personneID: string;
  newsletter:string;
  commentaire:string;
  loadingC=false;
  metier: string;
  extraDiplome: string;
  blockDiplome: JSON;
  blockStatus: JSON;
  blockD: any = [];
  blockS: any = [];
  statusS: string;
  dateD: string;
  dateF: string;
  detailsS: string;
  secteurActivite: string;
  indexDiplome: number;
  indexStatut: number;
  date: string;
  statusDip: string;

  addForm: FormGroup;
  rows: FormArray;
  rowDip: FormArray;
  loading = false;

  SessionFormation: Formation[];
  LengthSessionFormation: boolean = false;


  submitted = false;
  isShowStatut = false;
  isShowDiplome = false;
  dipTable: any = [];
  staTable: any = [];
  lengthS: number;
  lengthD: number;
  showChamps = false;
  loadingFinal = false;

  constructor(private fb: FormBuilder,private formationService: FormationService,
    private dipl: FormBuilder, private route: ActivatedRoute, private ProspectService: ProspectService, private DossierSecretaryService: DossierSecretaryService) {

    this.addForm = this.fb.group({
      items: [null, Validators.required],
      items_value: ["no", Validators.required]
    });
    this.rows = this.fb.array([]);


    this.addForm = this.dipl.group({
      items: [null, Validators.required],
      items_value: ["no", Validators.required]
    });
    this.rowDip = this.dipl.array([]);

  }

  ngOnInit(): void {

    this.sub = this.route.params.subscribe(params => {
      this.id = +params['id']; // (+) converts string 'id' to a number
    });
    console.log(this.id);
    this.getProspectByID(this.id);
    this.getSessionFormation(this.id);

    this.addForm.get("items_value").setValue("yes");
    this.addForm.addControl("rows", this.rows);
    this.addForm.addControl("rowDip", this.rowDip);
    this.indexStatut = 0;
    this.indexDiplome = 0;
    this.showChamps = false;

  }
  onSubmit(form: NgForm) {
    console.log('Your form data : ', form.value);
  }
  /** Recuperation Prospect by ID
   * @param  {} id_user
   */
  getProspectByID(id_user) {
    this.ProspectService
      .getProspectByID(id_user).
    subscribe(
      response => {
        console.log(response);

        let UserInformations = [];
        for (let key in response) {
          if (response.hasOwnProperty(key)) {
            UserInformations[key] = response[key];
          }
        }
        this.nom = UserInformations['nom']
        this.testinput = UserInformations['photodeprofil']
        this.prenom = UserInformations['prenom']
        this.email = UserInformations['email']
        this.pays = UserInformations['pays']
        this.ville = UserInformations['ville']
        this.telephone = UserInformations['telephone']
        this.cp = UserInformations['codePostal']
        this.AdressePostal = UserInformations['adresse_postal']
        this.emailautre = UserInformations['emailautre']
        this.diplome = UserInformations['diplome']
        this.dateNaissance = UserInformations['dateNaissance']
        this.details = UserInformations['details']
        this.niveauEtude = UserInformations['niveauEtude']
        this.parcours = UserInformations['parcours'] || '';
        this.photodeprofil = UserInformations['photodeprofil']
        this.status = UserInformations['status']
        this.metier = UserInformations['metier']
        this.extraDiplome = UserInformations['extraDiplome']
        this.personneID = UserInformations['personneID']
        this.newsletter = UserInformations['newsletter']
        this.getCommentaireDashboard( this.personneID)

        if( UserInformations['blockDiplome'])
        { this.dipTable = UserInformations['blockDiplome'].data; }
        if( UserInformations['blockStatus'])
        { this.staTable = UserInformations['blockStatus'].data;  }
        this.lengthS = this.staTable.length+1;
        this.lengthD = this.dipTable.length+1;

        // Partie diplome
        // if (UserInformations['blockDiplome']) {
        //   this.statusDip = (UserInformations['blockDiplome'].status) || '';
        //   this.date = (UserInformations['blockDiplome'].date) || '';
        //   this.details = (UserInformations['blockDiplome'].details) || '';
        //   // this.details = UserInformations['blockDiplome']['details'] || '';

        //   this.blockDiplome = (UserInformations['blockDiplome']['data']);
        //   for (let key in this.blockDiplome) {
        //     this.blockD[key] = this.blockDiplome[key];
        //     this.indexDiplome = parseInt(key) + 1;
        //   }
        // }


        // // Partie Statut
        // if (UserInformations['blockStatus']) {

        //   this.statusS = (UserInformations['blockStatus'].statusS) || '';
        //   this.metier = (UserInformations['blockStatus'].metier) || '';
        //   this.dateD = (UserInformations['blockStatus'].dateD) || '';
        //   this.dateF = (UserInformations['blockStatus'].dateF) || '';
        //   this.detailsS = (UserInformations['blockStatus'].detailsS) || '';
        //   this.secteurActivite = (UserInformations['blockStatus'].secteurActivite) || '';

        //   this.blockStatus = (UserInformations['blockStatus']['data']);
        //   for (let key in this.blockStatus) {
        //     this.blockS[key] = this.blockStatus[key];
        //     this.indexStatut = parseInt(key) + 1;
        //   }
        // }


      },
      error => {
        console.log(error)
      }
    );
  }

  getCommentaireDashboard(personneID){
    this.ProspectService
    .getCommentaireDashboard(personneID).
    subscribe(
      response => {
        this.commentaire = response['commentaire'];

      },
      error => {
        console.log(error)
      }
    );
  }

  saveComment(){
    this.loadingC=true;
    this.ProspectService
    .saveCommentaireDashboard({
      personneID: this.personneID,
      commentaire: this.commentaire
    }).
    subscribe(
      response => {
        this.loadingC=false;


      },
      error => {
        console.log(error)
      }
    );
  }

 /** Enregistrement Parcour Pro
   * @param  {} data
   */

  save_parcour() {
    this.loadingFinal = true;
    this.DossierSecretaryService
      .save_parcour({
        staTable: this.staTable,
        dipTable: this.dipTable,
        id_user: this.id
      }).subscribe(
        response => {
          this.loadingFinal = false;

        },
        error => {
          console.log('error')
        }
      );


  }

  onAddStatus() {
    var element = {
      status: "",
      date: "",
      details: ""
    };
    element.status = "";
    element.date = "";
    element.details = "";
    this.blockS.data.push(element);
  }

  onAddRow() {
    console.log(this.blockS.length);

    var element = {
      statusS: "",
      metier: "",
      dateD: "",
      dateF: "",
      detailsS: "",
      secteurActivite: ""
    };
    element.statusS = "";
    element.dateD = "";
    element.dateF = "";
    element.metier = "";
    element.detailsS = "";
    element.secteurActivite = "";

    this.blockS.push(element);
    // this.rows.push(this.createItemFormGroup());

    this.indexStatut = this.blockS.length;
  }

  onAddDiplome() {
    //  console.log (this.blockD.length);
    var element = {
      status: "",
      date: "",
      details: ""
    };
    element.status = "";
    element.date = "";
    element.details = "";
    this.blockD.push(element);
    this.indexDiplome = this.blockD.length;
  }

  /**recuperation Devis By IDuser
   * @param  {} id_user
   */
  getSessionFormation(id_user) {
    this.ProspectService
      .getSessionbyUser(id_user)
      .subscribe(
        SessionFormation => {
          this.SessionFormation = SessionFormation;
          if (SessionFormation.length > 0) this.LengthSessionFormation = true
          console.log(SessionFormation)

        }
      );

  }
    displayStatut() {
      this.isShowStatut = true;
      this.isShowDiplome = false;
      this.showChamps = true;
      this.statusS = 'Sélectionner votre statut';
      this.secteurActivite = 'Sélectionner votre secteur d’activité';
    }
    displayDiplome() {
      this.isShowDiplome = true;
      this.isShowStatut = false;
      this.showChamps = true;
      this.statusDip = 'Sélectionner votre diplôme';
    }
    addDiplome(data){
      this.dipTable.push(data);
      this.lengthD = this.dipTable.length+1;
      this.statusDip = 'Sélectionner votre diplôme';
      this.date = '';
      this.details = '';
    }
    removeDiplome(index){
      this.dipTable.splice(index, 1);
      this.lengthD = this.dipTable.length+1;

    }
    
    addStatut(data){
      this.staTable.push(data);
      this.lengthS = this.staTable.length+1;
      this.statusS = 'Sélectionner votre statut';
      this.secteurActivite = 'Sélectionner votre secteur d’activité';
      this.detailsS = '';
      this.dateD= '';
      this.dateF= '';
      this.metier= '';

    }
    removeStatut(index){
      this.staTable.splice(index, 1);
      this.lengthS = this.staTable.length+1;
  
    }
  saveDevis(sessionID){

    this.formationService
    .downloadDevis(sessionID, this.personneID)
    .subscribe(
        response => {
            

          },
        error => {
           // this.errorMessage = <any>error;
        }
    );

  }


}
