import { Component, OnInit } from '@angular/core';
import { Forgottenpsswrd, ForgottenpsswrdService } from '../../forgottenpsswrd.service';
import { AccountService, AlertService } from '../../_services';

@Component({
  selector: 'app-dashboard',
  templateUrl: './forgottenpsswrd.component.html',
  styleUrls: ['./forgottenpsswrd.component.css']
})


export class ForgottenpsswrdComponent implements OnInit {
  loading = false;


  constructor(private forgottenpsswrdService: ForgottenpsswrdService,  private alertService: AlertService) {}
  ngOnInit(): void { }
  navigate(link, e) {
    window.open(link);
  }
  
  /** Envoyer mail contient le nouveau mot de passe
   * @param  {} email
   */
  sendpsswd(email) {
    this.alertService.clear();
    this.loading = true;
    this.forgottenpsswrdService
        .sendemail({
          email: email,
      })
        .subscribe(
            response => {
              console.log(response)
              if(typeof response['error'] != 'undefined') {
                this.alertService.error(response['error'], { keepAfterRouteChange: true });
              }
              if(typeof response['success'] != 'undefined') {
                this.alertService.success(response['success'], { keepAfterRouteChange: true });
              }
              // 
              this.loading = false;
            },
            error => {
              console.log('error')
              console.log(error)
              this.loading = false;
            }
        );
  }
 
}
