import {
  Injectable
} from '@angular/core';
import {
  OktaAuthService
} from '@okta/okta-angular';
import {
  Http,
  Headers,
  RequestOptions
} from '@angular/http';
import {
  Observable
} from "rxjs/Observable";
import 'rxjs/Rx';
import {
  environment
} from './environments/environment';
import {
  map
} from 'rxjs/operators';

export interface Formation {
  emaill: String,
    data: String,
    id: Number,
    renseignement: String,
    userInformations: String,
    profilePic: String
}

const API_URL: string = environment.API_URL;

@Injectable({
  providedIn: 'root'
})

export class FormationService {

  private accessToken;
  private headers;

  constructor(private oktaAuth: OktaAuthService, private http: Http) {
    this.init();
  }


  async init() {
    this.headers = new Headers({
      Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('user')).token
    });
  }



  /**Récuperer toute les formations avec les sessions
   */
  getFormations() {

    return this.http.get(API_URL + '/api/formations', new RequestOptions({
        headers: this.headers
      }))
      .pipe(map(res => {
        let modifiedResult = res.json();
        modifiedResult = modifiedResult.map(function (formation) {
          return formation;
        });
        return modifiedResult;
      }));
  }

  /*** Fonction qui permet de sauvegarder un devis 
   * @param  {} id
   * @returns Observable
   */
  saveDevis(id): Observable < Formation > {

    return this.http.post(API_URL + '/api/saveDevis/' + id, '',
      new RequestOptions({
        headers: this.headers
      })
    ).pipe(map(res => res.json()));


  }
  /** générer le pdf devis
   * @param  {} id
   * @param  {} idperso
   */
  downloadDevis(id, idperso) {
    return this.http.post(API_URL + '/downloaddevis.php?id_personne=' + idperso + '&id_session=' + id, '', new RequestOptions({
        headers: this.headers
      }))
      .pipe(map(res => {

        window.open(environment.EIDB_URL + '/pdf/' + res.text(), "s", "resizable=yes, toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=yes, copyhistory=no").blur();
        window.focus();
        return res;
      }));
  }
  /** recuperation information utilisateur
   * @returns Observable
   */
  getUserInformations(): Observable < Formation[] > {

    return this.http.get(API_URL + '/api/getUserInformations', new RequestOptions({
        headers: this.headers
      }))
      .pipe(map(res => res.json()));
  }



  /** Récupérere les sessions choisies de la part du prospect
   * @returns Observable
   */
  getSessionFormation(): Observable < Formation[] > {
    return this.http.get(API_URL + '/api/SessionFormation', new RequestOptions({
        headers: this.headers
      }))
      .pipe(map(res => {
        let modifiedResult = res.json();
        modifiedResult = modifiedResult.map(function (SessionFormation) {
          return SessionFormation;
        });
        return modifiedResult;
      }));

  }

  /** Save partie renseignement
   * @param  {} renseignement
   * @returns Observable
   */
  saveRenseignement(renseignement): Observable < Formation > {
    return this.http.post(API_URL + '/saveRenseignement', renseignement,
      new RequestOptions({
        headers: this.headers
      })
    ).pipe(map(res => res.json()));

  }
  /** save profil avec parcour pro
   * @param  {} data
   * @returns Observable
   */
  saveProfile(data): Observable < Formation > {
    return this.http.post(API_URL + '/api/saveProfile', data,
      new RequestOptions({
        headers: this.headers
      })
    ).pipe(map(res => res.json()));

  }
  /** recuperation renseignement devis
   * @param  {} id
   * @returns Observable
   */
  getRenseignement(id): Observable < Formation > {
    return this.http.post(API_URL + '/getRenseignement/' + id, new RequestOptions({
        headers: this.headers
      }))
      .pipe(map(res => res.json()));

  }

}
