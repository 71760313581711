import { Component } from '@angular/core';
import { Router } from '@angular/router';

import { AccountService } from '../_services';

@Component({ templateUrl: 'mention.component.html' })
export class MentionComponent {
    constructor() 
    
    {
    }
}