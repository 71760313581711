import {
  Component
} from '@angular/core';
import {
  OktaAuthService
} from '@okta/okta-angular';
import {
  AccountService
} from './_services';
import {
  User
} from './_models';

@Component({
  // tslint:disable-next-line
  selector: 'body',
  template: '<router-outlet></router-outlet>'
})

export class AppComponent {

  user: User;

  constructor(private accountService: AccountService) {
    this.accountService.user.subscribe(x => this.user = x);
  }

  logout() {
    this.accountService.logout();
  }
}
